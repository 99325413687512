import { Fighter } from "../types";
import lon from "./legions-of-nagash";
import id from "./idoneth-deepkin";
import nh from "./nighthaunt";
import fec from "./flesh-eater-courts";
import ig from "./iron-golem";
import ub from "./untamed-beasts";
import bz from "./bonesplitterz";
import cc from "./corvus-cabal";
import cl from "./cypher-lords";
import tu from "./the-unmade";
import sf from "./splintered-fang";
import se from "./stormcast-eternals";
import ij from "./ironjawz";
import gg from "./gloomspite-gitz";
import dok from "./daughters-of-khaine";

const fighters: Fighter[] = [
  ...dok,
  ...gg,
  ...ij,
  ...se,
  ...sf,
  ...tu,
  ...cl,
  ...cc,
  ...bz,
  ...ig,
  ...fec,
  ...nh,
  ...id,
  ...lon,
  ...ub,
];

export default fighters;
