import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Shadow Piercer",
    faction: { type: "CORVUS_CABAL" },
    points: 185,
    movement: 5,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "SCOUT" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Shrike Talon",
    faction: { type: "CORVUS_CABAL" },
    points: 185,
    movement: 8,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "AGILE" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Cabalist with Familiar",
    faction: { type: "CORVUS_CABAL" },
    points: 60,
    movement: 5,
    toughness: 3,
    wounds: 8,
    runemarks: {
      0: { type: "SCOUT" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Cabalist with Spear",
    faction: { type: "CORVUS_CABAL" },
    points: 75,
    movement: 5,
    toughness: 3,
    wounds: 8,
    runemarks: {},
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 4,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Cabalist",
    faction: { type: "CORVUS_CABAL" },
    points: 65,
    movement: 5,
    toughness: 3,
    wounds: 8,
    runemarks: {},
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Spire Stalker",
    faction: { type: "CORVUS_CABAL" },
    points: 140,
    movement: 5,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "CHAMPION" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
];

export default warband;
