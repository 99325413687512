import { Faction, Fighter, Ability, Weapon } from "./types";

// Helpers

function toList<T>(items: T[]): string {
  return items.filter(i => !!i).join(", ");
}

function capitalise(string: string): string {
  if (string.length === 0) {
    return string;
  }

  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

function keyToString(key: string): string {
  return key
    .split("_")
    .map(capitalise)
    .join(" ");
}

function abilityToString(a: Ability): string {
  return keyToString(a.type);
}

// Exported functions

export function toFixed(n: number, digits: number = 2) {
  if (!n) {
    return "—"
  }

  return n.toFixed(digits);
}

export function factionToString(f: Faction): string {
  // prettier-ignore
  switch (f.type) {
    case "LEGIONS_OF_NAGASH": return "Legions of Nagash";
    case "FLESH_EATER_COURTS": return "Flesh-eater Courts";
    default: return keyToString(f.type);
  }
}

export function weaponToString(weapon: Weapon): string {
  return (
    keyToString(weapon.type) +
    ` (r${weapon.min_range > 0 ? `${weapon.min_range}-${weapon.max_range}` : weapon.max_range} a${weapon.attacks} s${weapon.strength} d${weapon.base_damage}/${weapon.critical_damage})`
  );
}

export function abilitiesToString(abilities: Fighter["runemarks"]): string {
  const { 0: first, 1: second, 2: third } = abilities;
  return toList([
    first ? abilityToString(first) : "",
    second ? abilityToString(second) : "",
    third ? abilityToString(third) : "",
  ]);
}
