import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Raptor-Prime with Longstrike Crossbow",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 280,
    movement: 4,
    toughness: 5,
    wounds: 30,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "ELITE" },
      2: { type: "SCOUT" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 6,
        max_range: 20,
        attacks: 1,
        strength: 5,
        base_damage: 4,
        critical_damage: 10,
      },
      1: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Hunter-Prime",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 210,
    movement: 4,
    toughness: 5,
    wounds: 30,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 0,
        max_range: 8,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
      1: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Raptor-Prime with Hurricane Crossbow",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 245,
    movement: 4,
    toughness: 5,
    wounds: 30,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "BULWARK" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 15,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 6,
      },
      1: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Vanguard-Raptor with Longstrike Crossbow",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 225,
    movement: 4,
    toughness: 5,
    wounds: 20,
    runemarks: {
      0: { type: "ELITE" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 6,
        max_range: 20,
        attacks: 1,
        strength: 5,
        base_damage: 4,
        critical_damage: 10,
      },
      1: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Vanguard-Raptor with Hurricane Crossbow",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 195,
    movement: 4,
    toughness: 5,
    wounds: 20,
    runemarks: {
      0: { type: "BULWARK" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 15,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 6,
      },
      1: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Vanguard-Hunter",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 165,
    movement: 4,
    toughness: 5,
    wounds: 20,
    runemarks: {},
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 0,
        max_range: 8,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
      1: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Gryph-hound",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 150,
    movement: 6,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "BEAST" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Aetherwing",
    faction: { type: "STORMCAST_ETERNALS" },
    points: 45,
    movement: 12,
    toughness: 2,
    wounds: 6,
    runemarks: {
      0: { type: "SCOUT" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 2,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
];

export default warband;
