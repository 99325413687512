import { Ability, Fighter, Faction } from "./types";

export function isLeaderRunemark(ability?: Ability): boolean {
  if (ability) {
    console.log("Is leader");
    return ability.type === "LEADER";
  }

  return false;
}

export function isLeader(fighter: Fighter): boolean {
  return (
    isLeaderRunemark(fighter.runemarks["0"]) ||
    isLeaderRunemark(fighter.runemarks["1"]) ||
    isLeaderRunemark(fighter.runemarks["2"])
  );
}

export function isNonLeader(fighter: Fighter): boolean {
  return !isLeader(fighter);
}

export function isFaction(faction: Faction["type"]) {
  return function(fighter: Fighter): boolean {
    return fighter.faction.type === faction;
  };
}
