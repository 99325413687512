import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Brute Boss with Boss Klaw and Brute Smasha",
    faction: { type: "IRONJAWZ" },
    points: 250,
    movement: 3,
    toughness: 5,
    wounds: 35,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "BERSERKER" },
      2: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 5,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Brute Boss with Boss Choppa",
    faction: { type: "IRONJAWZ" },
    points: 255,
    movement: 3,
    toughness: 5,
    wounds: 35,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 6,
        base_damage: 3,
        critical_damage: 6,
      },
    },
  },
  {
    name: "Ardboy Boss with Orruk-forged Choppa and Smasha",
    faction: { type: "IRONJAWZ" },
    points: 190,
    movement: 3,
    toughness: 4,
    wounds: 25,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 5,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Brute with Jagged Gore-hacka",
    faction: { type: "IRONJAWZ" },
    points: 180,
    movement: 3,
    toughness: 5,
    wounds: 25,
    runemarks: {
      0: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 5,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Brute with Gore-choppa",
    faction: { type: "IRONJAWZ" },
    points: 200,
    movement: 3,
    toughness: 5,
    wounds: 25,
    runemarks: {
      0: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 5,
        base_damage: 3,
        critical_damage: 6,
      },
    },
  },
  {
    name: "Brute with two Brute Choppas",
    faction: { type: "IRONJAWZ" },
    points: 180,
    movement: 3,
    toughness: 5,
    wounds: 25,
    runemarks: {
      0: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Ardboy with Orruk-forged Big Choppa",
    faction: { type: "IRONJAWZ" },
    points: 110,
    movement: 3,
    toughness: 4,
    wounds: 15,
    runemarks: {},
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Ardboy with Orruk-forged Choppa and Smasha",
    faction: { type: "IRONJAWZ" },
    points: 110,
    movement: 3,
    toughness: 4,
    wounds: 15,
    runemarks: {},
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Ardboy with Orruk-forged Choppa and Orruk-forged Shield",
    faction: { type: "IRONJAWZ" },
    points: 125,
    movement: 3,
    toughness: 5,
    wounds: 15,
    runemarks: {
      0: { type: "BULWARK" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
];

export default warband;
