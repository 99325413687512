import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Blissful One",
    faction: { type: "THE_UNMADE" },
    points: 220,
    movement: 8,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 6,
      },
    },
  },
  {
    name: "Joyous One",
    faction: { type: "THE_UNMADE" },
    points: 145,
    movement: 5,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "DESTROYER" },
    },
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Awakened One with Flail",
    faction: { type: "THE_UNMADE" },
    points: 60,
    movement: 4,
    toughness: 3,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "REACH_WEAPON",
        min_range: 0,
        max_range: 3,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
      1: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Awakened One with Brutal Polearm",
    faction: { type: "THE_UNMADE" },
    points: 60,
    movement: 4,
    toughness: 3,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Ascended One",
    faction: { type: "THE_UNMADE" },
    points: 125,
    movement: 4,
    toughness: 4,
    wounds: 10,
    runemarks: {
      0: { type: "WARRIOR" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
];

export default warband;
