import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Trueblood",
    faction: { type: "SPLINTERED_FANG" },
    points: 180,
    movement: 4,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Serpent Caller",
    faction: { type: "SPLINTERED_FANG" },
    points: 145,
    movement: 5,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "MYSTIC" },
    },
    weapons: {
      0: {
        type: "FANGS",
        min_range: 0,
        max_range: 2,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
      1: {
        type: "RANGED_WEAPON",
        min_range: 0,
        max_range: 8,
        attacks: 1,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Serpents",
    faction: { type: "SPLINTERED_FANG" },
    points: 65,
    movement: 6,
    toughness: 2,
    wounds: 8,
    runemarks: {
      0: { type: "BEAST" },
    },
    weapons: {
      0: {
        type: "FANGS",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Pureblood",
    faction: { type: "SPLINTERED_FANG" },
    points: 125,
    movement: 4,
    toughness: 4,
    wounds: 12,
    runemarks: {
      0: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Venomblood with Barbed Whip",
    faction: { type: "SPLINTERED_FANG" },
    points: 110,
    movement: 4,
    toughness: 5,
    wounds: 10,
    runemarks: {
      0: { type: "BULWARK" },
    },
    weapons: {
      0: {
        type: "REACH_WEAPON",
        min_range: 0,
        max_range: 3,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Venomblood with Spear and Shield",
    faction: { type: "SPLINTERED_FANG" },
    points: 135,
    movement: 4,
    toughness: 5,
    wounds: 10,
    runemarks: {
      0: { type: "BULWARK" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Venomblood with Duelling Blades",
    faction: { type: "SPLINTERED_FANG" },
    points: 85,
    movement: 5,
    toughness: 3,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Venomblood with Blade and Barbed Whip",
    faction: { type: "SPLINTERED_FANG" },
    points: 85,
    movement: 5,
    toughness: 3,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
      1: {
        type: "REACH_WEAPON",
        min_range: 0,
        max_range: 3,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Clearblood",
    faction: { type: "SPLINTERED_FANG" },
    points: 65,
    movement: 4,
    toughness: 3,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Clearblood with Shield",
    faction: { type: "SPLINTERED_FANG" },
    points: 70,
    movement: 4,
    toughness: 4,
    wounds: 10,
    runemarks: {
      0: { type: "BULWARK" },
    },
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
];

export default warband;
