import React, { useState } from "react";
import { Fighter, Faction } from "./types";
import f from "./warbands";
import * as cast from "./casters";
import * as filter from "./filters";
import { getStats, weaponKilliness, survivability } from "./statistics";
import { pick } from "./utils";

const fighters = f;

type CellProps = {
  value: number;
  mean: number;
  sd: number;
  kind?: "higher_is_better" | "lower_is_better";
};

function Cell(props: CellProps) {
  function distance(value: number, mean: number, sd: number) {
    const maxThreshold = mean + sd;
    const minThreshold = mean - sd;

    if (value > maxThreshold) {
      return "above_sd";
    }

    if (value < minThreshold) {
      return "below_sd";
    }

    return "within_sd";
  }

  return (
    <td
      style={{
        backgroundColor: pick(
          distance(props.value, props.mean, props.sd),
          pick(props.kind || "higher_is_better", {
            higher_is_better: {
              above_sd: "#77d075",
              below_sd: "#f7a368",
              within_sd: "transparent",
            },
            lower_is_better: {
              above_sd: "#f7a368",
              below_sd: "#77d075",
              within_sd: "transparent",
            },
          }),
        ),
      }}>
      {props.value}
    </td>
  );
}

type StatsOverviewProps = {
  stats: ReturnType<typeof getStats>;
};

function StatsOverview(props: StatsOverviewProps) {
  return (
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <th>Property</th>
            <th>Mean</th>
            {/* <th>Median</th> */}
            <th>Mode</th>
            <th>Standard Deviation</th>
            <th>Mean Point / Property</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Movement</td>
            <td>{props.stats.movement.mean}</td>
            {/* <td>{props.stats.movement.median}</td> */}
            <td>{props.stats.movement.mode.join(", ")}</td>
            <td>{props.stats.movement.sd}</td>
            <td>
              {props.stats.movement.mppmp} (±
              {props.stats.movement.mppmpsd})
            </td>
          </tr>
          <tr>
            <td>Toughness</td>
            <td>{props.stats.toughness.mean}</td>
            {/* <td>{props.stats.toughness.median}</td> */}
            <td>{props.stats.toughness.mode.join(", ")}</td>
            <td>{props.stats.toughness.sd}</td>
            <td>
              {props.stats.toughness.mppmp} (±
              {props.stats.toughness.mppmpsd})
            </td>
          </tr>
          <tr>
            <td>Wounds</td>
            <td>{props.stats.wounds.mean}</td>
            {/* <td>{props.stats.wounds.median}</td> */}
            <td>{props.stats.wounds.mode.join(", ")}</td>
            <td>{props.stats.wounds.sd}</td>
            <td>
              {props.stats.wounds.mppmp} (±
              {props.stats.wounds.mppmpsd})
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Critical Ratio</th>
            <th>AAA to Kill</th>
            <th>AAA to get Killed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.stats.critical_ratio}</td>
            <td>{props.stats.aaatk.join(" / ")}</td>
            <td>{props.stats.aaatgk.join(" / ")}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
}

function killinessToString(k: ReturnType<typeof weaponKilliness>) {
  return k.join(" / ");
}

function WeaponCell(props: { weapons: Fighter["weapons"] }) {
  const { 0: first, 1: second } = props.weapons;
  return (
    <td>
      {first && (
        <>
          <b>{cast.weaponToString(first)}:</b>
          <br />
          {killinessToString(weaponKilliness(first))}
        </>
      )}
      {second && (
        <>
          <br />
          <b>{cast.weaponToString(second)}:</b>
          <br />
          {killinessToString(weaponKilliness(second))}
        </>
      )}
    </td>
  );
}

type ListProps = {
  fighters: Fighter[];
  globalStats: ReturnType<typeof getStats>;
};

function List(props: ListProps) {
  const localStats = getStats(props.fighters);

  return (
    <React.Fragment>
      <details>
        <summary>Stats</summary>
        <StatsOverview stats={localStats} />
      </details>

      <h3>Fighters</h3>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Pts</th>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            {/* <th>Runemarks</th> */}
            <th>Survivability</th>
            <th>Killiness</th>
            {/* <th>P/M</th>
            <th>P/T</th>
            <th>P/W</th> */}
          </tr>
        </thead>
        <tbody>
          {props.fighters.map(fighter => (
            <tr>
              <td>{fighter.name}</td>
              <td>{fighter.points}</td>
              <Cell
                value={fighter.movement}
                mean={props.globalStats.movement.mean}
                sd={props.globalStats.movement.sd}
              />
              <Cell
                value={fighter.toughness}
                mean={props.globalStats.toughness.mean}
                sd={props.globalStats.toughness.sd}
              />
              <Cell
                value={fighter.wounds}
                mean={props.globalStats.wounds.mean}
                sd={props.globalStats.wounds.sd}
              />
              {/* <td>
                {cast.factionToString(fighter.faction)}
                {", "}
                {cast.abilitiesToString(fighter.runemarks)}
              </td> */}
              <td>{survivability(fighter).join(" / ")}</td>
              <WeaponCell weapons={fighter.weapons} />
              {/* <Cell
                value={fighter.points / fighter.movement}
                mean={props.globalStats.movement.mppmp}
                sd={props.globalStats.movement.mppmpsd}
                kind="lower_is_better"
                digits={2}
              />
              <Cell
                value={fighter.points / fighter.toughness}
                mean={props.globalStats.toughness.mppmp}
                sd={props.globalStats.toughness.mppmpsd}
                kind="lower_is_better"
                digits={2}
              />
              <Cell
                value={fighter.points / fighter.wounds}
                mean={props.globalStats.wounds.mppmp}
                sd={props.globalStats.wounds.mppmpsd}
                kind="lower_is_better"
                digits={2}
              /> */}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
}

type OptionProps = {
  faction: Faction["type"];
};

function Option(props: OptionProps) {
  return (
    <option value={props.faction}>
      {cast.factionToString({ type: props.faction })}
    </option>
  );
}

export default function App() {
  const factions: Faction["type"][] = [
    "GLOOMSPITE_GITZ",
    "STORMCAST_ETERNALS",
    "IRON_GOLEM",
    "NIGHTHAUNT",
    "IDONETH_DEEPKIN",
    "FLESH_EATER_COURTS",
    "LEGIONS_OF_NAGASH",
    "UNTAMED_BEASTS",
    "BONESPLITTERZ",
    "CORVUS_CABAL",
    "CYPHER_LORDS",
    "THE_UNMADE",
    "SPLINTERED_FANG",
    "IRONJAWZ",
  ];

  const stats = getStats(fighters);
  const [faction, setFaction] = useState<Faction["type"] | "">(factions[0]);

  return (
    <main>
      <h2>List Builder</h2>
      <select
        style={{ marginBottom: "2rem" }}
        onChange={event => setFaction(event.target.value as Faction["type"])}>
        {factions.map(option => (
          <Option faction={option} />
        ))}
        <option value="">All factions</option>
      </select>
      <details>
        <summary>Global Stats</summary>
        <StatsOverview stats={stats} />
      </details>
      <List
        globalStats={stats}
        fighters={
          faction ? fighters.filter(filter.isFaction(faction)) : fighters
        }
      />
    </main>
  );
}
