import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Thrallmaster",
    faction: { type: "CYPHER_LORDS" },
    points: 205,
    movement: 5,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "MYSTIC" },
    },
    weapons: {
      0: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 6,
      },
    },
  },
  {
    name: "Luminate",
    faction: { type: "CYPHER_LORDS" },
    points: 175,
    movement: 5,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "MYSTIC" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Mindbound",
    faction: { type: "CYPHER_LORDS" },
    points: 75,
    movement: 5,
    toughness: 3,
    wounds: 10,
    runemarks: {
      0: { type: "MINION" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Mirrorblade with Double-bladed Sword",
    faction: { type: "CYPHER_LORDS" },
    points: 80,
    movement: 5,
    toughness: 3,
    wounds: 10,
    runemarks: {
      0: { type: "DESTROYER" },
      1: { type: "MINION" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Mirrorblade with Duelling Swords",
    faction: { type: "CYPHER_LORDS" },
    points: 115,
    movement: 5,
    toughness: 3,
    wounds: 10,
    runemarks: {
      0: { type: "MINION" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Mirrorblade with Glaive",
    faction: { type: "CYPHER_LORDS" },
    points: 120,
    movement: 5,
    toughness: 3,
    wounds: 10,
    runemarks: {
      0: { type: "DESTROYER" },
      1: { type: "MINION" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
];

export default warband;
