/**
 * Functional alternative to a switch/case statement.
 *
 * Note that unlike a switch/case, a pick call actually evaluates all its
 * values (branches). Don't use it for expensive operations or anything
 * that has side-effects.
 */

export function pick<Lookup, Key extends keyof Lookup>(key: Key, lookup: Lookup) {
  return lookup[key];
}