import { Fighter } from "../types";

const necromancer: Fighter = {
  name: "Necromancer",
  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 195,
  movement: 4,
  toughness: 4,
  wounds: 25,
  runemarks: {
    0: { type: "LEADER" },
    1: { type: "MYSTIC" },
  },
  weapons: {
    0: {
      type: "BLAST",
      min_range: 3,
      max_range: 7,
      attacks: 2,
      strength: 3,
      base_damage: 3,
      critical_damage: 6,
    },
    1: {
      type: "CLUB",
      min_range: 0,
      max_range: 2,
      attacks: 3,
      strength: 4,
      base_damage: 1,
      critical_damage: 4,
    },
  },
};

const seneschal: Fighter = {
  name: "Seneschal",

  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 120,

  movement: 3,
  toughness: 3,
  wounds: 15,

  runemarks: {
    0: { type: "CHAMPION" },
    1: { type: "ELITE" },
  },

  weapons: {
    0: {
      type: "SWORD",
      min_range: 0,
      max_range: 1,
      attacks: 4,
      strength: 5,
      base_damage: 2,
      critical_damage: 5,
    },
  },
};

const guardBlade: Fighter = {
  name: "Grave Guard with Wight Blade and Crypt Shield",

  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 90,

  movement: 3,
  toughness: 4,
  wounds: 10,

  runemarks: {
    0: { type: "ELITE" },
  },

  weapons: {
    0: {
      type: "SWORD",
      min_range: 0,
      max_range: 1,
      attacks: 3,
      strength: 4,
      base_damage: 2,
      critical_damage: 4,
    },
  },
};

const guardGreatBlade: Fighter = {
  name: "Grave Guard with Great Wight Blade",

  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 85,

  movement: 3,
  toughness: 3,
  wounds: 10,

  runemarks: {
    0: { type: "ELITE" },
  },

  weapons: {
    0: {
      type: "SWORD",
      min_range: 0,
      max_range: 1,
      attacks: 3,
      strength: 5,
      base_damage: 2,
      critical_damage: 5,
    },
  },
};

const skeletonChampion: Fighter = {
  name: "Skeleton Champion",

  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 80,

  movement: 3,
  toughness: 4,
  wounds: 12,

  runemarks: {
    0: { type: "CHAMPION" },
  },

  weapons: {
    0: {
      type: "SWORD",
      min_range: 0,
      max_range: 1,
      attacks: 4,
      strength: 3,
      base_damage: 1,
      critical_damage: 3,
    },
  },
};
const skeletonWarriorBlade: Fighter = {
  name: "Skeleton Warrior with Ancient Blade",

  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 55,

  movement: 3,
  toughness: 4,
  wounds: 8,

  runemarks: {},

  weapons: {
    0: {
      type: "SWORD",
      min_range: 0,
      max_range: 1,
      attacks: 3,
      strength: 3,
      base_damage: 1,
      critical_damage: 3,
    },
  },
};
const skeletonWarriorSpear: Fighter = {
  name: "Skeleton Warrior with Ancient Spear",

  faction: { type: "LEGIONS_OF_NAGASH" },
  points: 55,

  movement: 3,
  toughness: 4,
  wounds: 8,

  runemarks: {},

  weapons: {
    0: {
      type: "SWORD",
      min_range: 0,
      max_range: 1,
      attacks: 2,
      strength: 3,
      base_damage: 1,
      critical_damage: 4,
    },
  },
};

const warband: Fighter[] = [
  necromancer,
  seneschal,
  guardBlade,
  guardGreatBlade,
  skeletonChampion,
  skeletonWarriorBlade,
  skeletonWarriorSpear,
];

export default warband;
