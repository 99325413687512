import { Fighter, Weapon } from "./types";
import { pick } from "./utils";
import fighters from "./warbands";

function mean(vals: number[]) {
  const total = vals.reduce((acc, val) => acc + val, 0);
  return total / vals.length;
}

export function median(vals: number[]) {
  const values = vals.sort();

  if (values.length % 2 === 0) {
    // Even number of values. We need to the mean of the middle two numbers.
    return (values[values.length / 2] + values[values.length / 2 + 1]) / 2;
  }

  // Odd number of values. We simply get the middle number.
  return values[(values.length + 1) / 2];
}

export function mode(vals: number[]) {
  const counter = new Map<number, number>();
  let result: number[] = [];
  let maxFreq = 0;

  vals.forEach(value => {
    if (counter.has(value)) {
      // We have to help TS a little here...
      const prevValue = counter.get(value) as number;
      counter.set(value, prevValue + 1);
    } else {
      counter.set(value, 1);
    }

    // We have to help TS a little here...
    const valueFreq = counter.get(value) as number;

    if (valueFreq > maxFreq) {
      // New max frequency
      maxFreq = valueFreq;
      result = [value];
    } else if (valueFreq === maxFreq) {
      // Tied frequency
      result.push(value);
    }
  });

  return result;
}

export function sd(vals: number[]) {
  const m = mean(vals);
  const total = vals
    .map(v => v - m) // Subtract mean
    .map(v => v * v) // Square
    .reduce((acc, v) => acc + v, 0); // Sum up

  return Math.sqrt(total / (vals.length - 1));
}

// Figher-specific

const modelFighers = {
  chaff: fighters.find(f => f.name === "Plains-runner") as Fighter,
  line_infantry: fighters.find(f => f.name === "Spire Stalker") as Fighter,
  elite: fighters.find(f => f.name === "Brute with Gore-choppa") as Fighter,
};

type CountableProps = "points" | "movement" | "toughness" | "wounds";

export function averages(fighters: Fighter[], property: CountableProps) {
  const propVals = fighters.map(f => f[property]);
  const points = fighters.map(f => f.points);

  const prop = {
    mean: dec(mean(propVals)),
    mode: mode(propVals).map(dec),
    median: dec(median(propVals)),
    sd: dec(sd(propVals)),
  };

  const derived = {
    mppmp: dec(mean(points) / prop.mean), // Mean points / Mean property
    mppmpsd: dec(sd(fighters.map(f => f.points / f[property]))),
  };

  return {
    ...prop,
    ...derived,
  };
}

function critRatio(fighters: Fighter[]) {
  const weapons = fighters
    .flatMap(fighter => [fighter.weapons["0"], fighter.weapons["1"]])
    .filter(w => !!w) as Weapon[]; // Have to cast becase TS ¯\_(ツ)_/¯

  const meanBaseDmg =
    weapons.reduce((acc, weapon) => acc + weapon.base_damage, 0) /
    weapons.length;

  const meanCritDmg =
    weapons.reduce((acc, weapon) => acc + weapon.critical_damage, 0) /
    weapons.length;

  return dec(meanCritDmg / meanBaseDmg);
}

function attackActionsToKill(fighters: Fighter[]): [number, number, number] {
  const killinesses = fighters
    .flatMap(f => [f.weapons[0], f.weapons[1]])
    .filter(w => !!w)
    .map(w => weaponKilliness(w as Weapon));

  return [
    dec(mean(killinesses.map(k => k[0]))), // Mean of attacks vs the first fighter type
    dec(mean(killinesses.map(k => k[1]))), // Mean of attacks vs the second fighter type
    dec(mean(killinesses.map(k => k[2]))), // Mean of attacks vs the third fighter type
  ];
}

function attackActionsToGetKilled(fighters: Fighter[]) {
  const survivabilities = fighters.map(survivability);

  return [
    dec(mean(survivabilities.map(s => s[0]))), // Mean of attacks received vs the first fighter type
    dec(mean(survivabilities.map(s => s[1]))), // Mean of attacks received vs the first fighter type
    dec(mean(survivabilities.map(s => s[2]))), // Mean of attacks received vs the first fighter type
  ];
}

export function getStats(fighters: Fighter[]) {
  return {
    toughness: averages(fighters, "toughness"),
    movement: averages(fighters, "movement"),
    wounds: averages(fighters, "wounds"),
    critical_ratio: critRatio(fighters),
    aaatk: attackActionsToKill(fighters),
    aaatgk: attackActionsToGetKilled(fighters),
  };
}

function baseDmgProbability(roll: "3+" | "4+" | "5+") {
  return (1 / 6) * pick(roll, { "3+": 3, "4+": 2, "5+": 1 });
}

function weaponDmgPotential(fighter: Fighter, weapon: Weapon) {
  return (
    weapon.attacks *
      baseDmgProbability(strengthVsToughness(fighter, weapon)) *
      weapon.base_damage +
    weapon.attacks * (1 / 6) * weapon.critical_damage
  );
}

function attacksToKill(vsFighter: Fighter, weapon: Weapon) {
  const dmg = weaponDmgPotential(vsFighter, weapon);
  // return Math.ceil(vsFighter.wounds / dmg);
  // return vsFighter.wounds / dmg;
  
  return dec(dmg, 2);
}

export function weaponKilliness(weapon: Weapon): [number, number, number] {
  return [
    attacksToKill(modelFighers.chaff, weapon),
    attacksToKill(modelFighers.line_infantry, weapon),
    attacksToKill(modelFighers.elite, weapon),
  ];
}

function strengthVsToughness(fighter: Fighter, weapon: Weapon) {
  return weapon.strength === fighter.toughness
    ? "4+"
    : weapon.strength > fighter.toughness
    ? "3+"
    : "5+";
}

function dec(n: number, decimals = 2) {
  const decimalShift = Math.pow(10, decimals)
  return Math.round(n * decimalShift) / decimalShift;
}

function attacksToGetKilled(fighter: Fighter, weapon: Weapon) {
  const dmg = weaponDmgPotential(fighter, weapon);
  // return Math.ceil(fighter.wounds / dmg);
  return dec(fighter.wounds / dmg);
  // return dec(dmg, 2)
}

export function survivability(fighter: Fighter) {
  return [
    attacksToGetKilled(fighter, modelFighers.chaff.weapons[0]),
    attacksToGetKilled(fighter, modelFighers.line_infantry.weapons[0]),
    attacksToGetKilled(fighter, modelFighers.elite.weapons[0]),
  ];
}
