import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Dreadwarden",
    faction: { type: "NIGHTHAUNT" },
    points: 150,
    movement: 5,
    toughness: 4,
    wounds: 16,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "MINION" },
      2: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Extoller of Shyish",
    faction: { type: "NIGHTHAUNT" },
    points: 195,
    movement: 6,
    toughness: 5,
    wounds: 20,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SCYTHE",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Grimghast Reaper",
    faction: { type: "NIGHTHAUNT" },
    points: 135,
    movement: 6,
    toughness: 5,
    wounds: 10,
    runemarks: {
      0: { type: "ELITE" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SCYTHE",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Chainrasp",
    faction: { type: "NIGHTHAUNT" },
    points: 80,
    movement: 5,
    toughness: 4,
    wounds: 8,
    runemarks: {
      0: { type: "MINION" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "CLUB",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Spirit Host",
    faction: { type: "NIGHTHAUNT" },
    points: 220,
    movement: 5,
    toughness: 5,
    wounds: 30,
    runemarks: {
      0: { type: "DESTROYER" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 6,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Glaivewraith Stalker",
    faction: { type: "NIGHTHAUNT" },
    points: 120,
    movement: 5,
    toughness: 5,
    wounds: 10,
    runemarks: {
      0: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
];

export default warband;
