import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Dominar",
    faction: { type: "IRON_GOLEM" },
    points: 175,
    movement: 4,
    toughness: 4,
    wounds: 20,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "HAMMER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 5,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Ogor Breacher",
    faction: { type: "IRON_GOLEM" },
    points: 235,
    movement: 4,
    toughness: 5,
    wounds: 30,
    runemarks: {
      0: { type: "DESTROYER" },
    },
    weapons: {
      0: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 2,
        strength: 6,
        base_damage: 4,
        critical_damage: 8,
      },
    },
  },
  {
    name: "Signifer",
    faction: { type: "IRON_GOLEM" },
    points: 120,
    movement: 4,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "ICON_BEARER" },
    },
    weapons: {
      0: {
        type: "HAMMER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Drillmaster",
    faction: { type: "IRON_GOLEM" },
    points: 125,
    movement: 5,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "BERSERKER" },
    },
    weapons: {
      0: {
        type: "CLUB",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
      1: {
        type: "REACH_WEAPON",
        min_range: 0,
        max_range: 3,
        attacks: 4,
        strength: 4,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Prefector",
    faction: { type: "IRON_GOLEM" },
    points: 125,
    movement: 4,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "BRUTE" },
    },
    weapons: {
      0: {
        type: "HAMMER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Iron Legionary",
    faction: { type: "IRON_GOLEM" },
    points: 80,
    movement: 4,
    toughness: 5,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "CLUB",
        min_range: 0,
        max_range: 1,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Iron Legionary with Bolas",
    faction: { type: "IRON_GOLEM" },
    points: 65,
    movement: 4,
    toughness: 4,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "REACH_WEAPON",
        min_range: 0,
        max_range: 3,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
      1: {
        type: "CLUB",
        min_range: 0,
        max_range: 1,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Armator",
    faction: { type: "IRON_GOLEM" },
    points: 90,
    movement: 3,
    toughness: 4,
    wounds: 12,
    runemarks: {},
    weapons: {
      0: {
        type: "HAMMER",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Iron Legionarу with Twin Hammers",
    faction: { type: "IRON_GOLEM" },
    points: 70,
    movement: 4,
    toughness: 4,
    wounds: 10,
    runemarks: {},
    weapons: {
      0: {
        type: "HAMMER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
];

export default warband;
