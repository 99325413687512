import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Savage Morboy Boss",
    faction: { type: "BONESPLITTERZ" },
    points: 205,
    movement: 4,
    toughness: 4,
    wounds: 25,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 3,
        critical_damage: 6,
      },
    },
  },
  {
    name: "Savage Boss with Chompa and Bone Shield",
    faction: { type: "BONESPLITTERZ" },
    points: 185,
    movement: 4,
    toughness: 4,
    wounds: 25,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Savage Orruk Arrowboy Boss",
    faction: { type: "BONESPLITTERZ" },
    points: 125,
    movement: 4,
    toughness: 4,
    wounds: 25,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "DESTROYER" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 15,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
      1: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Savage Orruk with Savage Stikka and Bone Shield",
    faction: { type: "BONESPLITTERZ" },
    points: 85,
    movement: 4,
    toughness: 4,
    wounds: 15,
    runemarks: {},
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Savage Big Stabbas",
    faction: { type: "BONESPLITTERZ" },
    points: 175,
    movement: 4,
    toughness: 3,
    wounds: 30,
    runemarks: {},
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 5,
        base_damage: 3,
        critical_damage: 6,
      },
    },
  },
  {
    name: "Savage Orruk Morboy with Bone Totem",
    faction: { type: "BONESPLITTERZ" },
    points: 110,
    movement: 4,
    toughness: 3,
    wounds: 15,
    runemarks: {
      0: { type: "MYSTIC" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 3,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Savage Orruk Arrowboy",
    faction: { type: "BONESPLITTERZ" },
    points: 90,
    movement: 4,
    toughness: 3,
    wounds: 15,
    runemarks: {
      0: { type: "DESTROYER" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 15,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
      1: {
        type: "UNARMED",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Savage Orruk with Chompa and Bone Shield",
    faction: { type: "BONESPLITTERZ" },
    points: 90,
    movement: 4,
    toughness: 4,
    wounds: 15,
    runemarks: {},
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Savage Orruk Morboy",
    faction: { type: "BONESPLITTERZ" },
    points: 110,
    movement: 4,
    toughness: 3,
    wounds: 15,
    runemarks: {
      0: { type: "ELITE" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
];

export default warband;
