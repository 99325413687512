import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Crypt Haunter",
    faction: { type: "FLESH_EATER_COURTS" },
    points: 255,
    movement: 6,
    toughness: 4,
    wounds: 40,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "ELITE" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Crypt Infernal",
    faction: { type: "FLESH_EATER_COURTS" },
    points: 300,
    movement: 10,
    toughness: 4,
    wounds: 40,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "AGILE" },
      2: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Crypt Ghast",
    faction: { type: "FLESH_EATER_COURTS" },
    points: 125,
    movement: 5,
    toughness: 3,
    wounds: 16,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Crypt Flayer",
    faction: { type: "FLESH_EATER_COURTS" },
    points: 235,
    movement: 10,
    toughness: 4,
    wounds: 30,
    runemarks: {
      0: { type: "AGILE" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Crypt Horror",
    faction: { type: "FLESH_EATER_COURTS" },
    points: 190,
    movement: 6,
    toughness: 4,
    wounds: 30,
    runemarks: {
      0: { type: "ELITE" },
    },
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Crypt Ghoul",
    faction: { type: "FLESH_EATER_COURTS" },
    points: 55,
    movement: 5,
    toughness: 3,
    wounds: 8,
    runemarks: {},
    weapons: {
      0: {
        type: "CLAWS",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
];

export default warband;
