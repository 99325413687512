import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Morrsarr Lochian Prince",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 265,
    movement: 10,
    toughness: 4,
    wounds: 35,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "BERSERKER" },
      2: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Ishlaen Lochian Prince",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 260,
    movement: 10,
    toughness: 4,
    wounds: 35,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "BULWARK" },
      2: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Namarti Thrall Icon Bearer",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 140,
    movement: 5,
    toughness: 3,
    wounds: 16,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "MINION" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Namarti Reaver Icon Bearer",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 135,
    movement: 6,
    toughness: 3,
    wounds: 16,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "AGILE" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 15,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
      1: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 4,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Morrsarr Guard",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 200,
    movement: 10,
    toughness: 4,
    wounds: 25,
    runemarks: {
      0: { type: "BERSERKER" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Ishlean Guard",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 195,
    movement: 10,
    toughness: 4,
    wounds: 25,
    runemarks: {
      0: { type: "BULWARK" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Namarti Thrall",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 80,
    movement: 8,
    toughness: 3,
    wounds: 8,
    runemarks: {
      0: { type: "MINION" },
    },
    weapons: {
      0: {
        type: "AXE",
        min_range: 0,
        max_range: 4,
        attacks: 4,
        strength: 3,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Namarti Reaver",
    faction: { type: "IDONETH_DEEPKIN" },
    points: 75,
    movement: 6,
    toughness: 3,
    wounds: 8,
    runemarks: {
      0: { type: "AGILE" },
    },
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 15,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
      1: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
];

export default warband;
