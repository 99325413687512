import { Fighter } from "../types";

const warband: Fighter[] = [
  {
    name: "Boingrot Boss",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 265,
    movement: 8,
    toughness: 5,
    wounds: 24,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "ELITE" },
      2: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 4,
        strength: 5,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Squig Hopper Boss",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 250,
    movement: 10,
    toughness: 4,
    wounds: 22,
    runemarks: {
      0: { type: "LEADER" },
      1: { type: "ELITE" },
      2: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 5,
        strength: 5,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Moonclan Boss",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 150,
    movement: 4,
    toughness: 4,
    wounds: 16,
    runemarks: {
      0: { type: "LEADER" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 4,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Boingrot Bounder",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 220,
    movement: 8,
    toughness: 5,
    wounds: 18,
    runemarks: {
      0: { type: "ELITE" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 3,
        strength: 5,
        base_damage: 2,
        critical_damage: 5,
      },
    },
  },
  {
    name: "Squig Hopper",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 200,
    movement: 10,
    toughness: 4,
    wounds: 16,
    runemarks: {
      0: { type: "ELITE" },
      1: { type: "FLY" },
    },
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 5,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Squig Herder",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 45,
    movement: 4,
    toughness: 3,
    wounds: 8,
    runemarks: {
      0: { type: "AGILE" },
    },
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },

  {
    name: "Cave Squig",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 140,
    movement: 4,
    toughness: 4,
    wounds: 15,
    runemarks: {
      0: { type: "BEAST" },
    },
    weapons: {
      0: {
        type: "FANGS",
        min_range: 0,
        max_range: 1,
        attacks: 4,
        strength: 5,
        base_damage: 2,
        critical_damage: 4,
      },
    },
  },
  {
    name: "Shoota",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 65,
    movement: 4,
    toughness: 3,
    wounds: 8,
    runemarks: {},
    weapons: {
      0: {
        type: "RANGED_WEAPON",
        min_range: 3,
        max_range: 12,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
      1: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Stabba",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 70,
    movement: 4,
    toughness: 4,
    wounds: 8,
    runemarks: {},
    weapons: {
      0: {
        type: "SWORD",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 3,
      },
    },
  },
  {
    name: "Stabba with Barbed Net",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 45,
    movement: 4,
    toughness: 3,
    wounds: 8,
    runemarks: {
      0: { type: "TRAPPER" },
    },
    weapons: {
      0: {
        type: "DAGGER",
        min_range: 0,
        max_range: 1,
        attacks: 3,
        strength: 3,
        base_damage: 1,
        critical_damage: 2,
      },
    },
  },
  {
    name: "Stabba with Pokin' Spear",
    faction: { type: "GLOOMSPITE_GITZ" },
    points: 70,
    movement: 4,
    toughness: 4,
    wounds: 8,
    runemarks: {},
    weapons: {
      0: {
        type: "SPEAR",
        min_range: 0,
        max_range: 2,
        attacks: 2,
        strength: 3,
        base_damage: 1,
        critical_damage: 4,
      },
    },
  },
];

export default warband;
